import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, mergeMap, of } from 'rxjs';

import { ActivityService } from '../shared/activity.service';
import { ActivitiesActions } from './activities.actions';

@Injectable()
export class ActivitiesEffects {
    constructor(
        private actions$: Actions,
        private activityService: ActivityService,
    ) {}

    loadActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.loadActivites),
            concatMap(() =>
                this.activityService.getAll().pipe(
                    map((activities) => ActivitiesActions.loadActivitesSuccess({ activities })),
                    catchError((error) => of(ActivitiesActions.loadActivitesFailure({ message: error }))),
                ),
            ),
        ),
    );

    loadActivitiesByEmployId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.getEmployeeActivitesById),
            mergeMap(({ id }) =>
                this.activityService.getById(id).pipe(
                    map((activities) => ActivitiesActions.loadActivitesByEmployeeIdSuccess({ activities })),
                    catchError((error) => of(ActivitiesActions.loadActivitesFailure({ message: error }))),
                ),
            ),
        ),
    );

    updateActivities$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ActivitiesActions.updateActivites),
            mergeMap((action) =>
                this.activityService
                    .updateActivity(action.activities.employeeId, action.activities.activityList)
                    .pipe(
                        map((activities) => ActivitiesActions.updateActivitesSuccess({ activities })),
                        catchError((error) => of(ActivitiesActions.updateActivitesFailure(error))),
                    ),
            ),
        ),
    );
}
