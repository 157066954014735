import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigurationService } from 'app/common/configuration';

import { EmployeeAvailability } from '../models/employeeAvailability';

@Injectable({
    providedIn: 'root',
})
export class EmployeeAvailabilityService {
    private baseUrl: string = '';

    constructor(
        private httpClient: HttpClient,
        appConfigurationService: AppConfigurationService,
    ) {
        this.baseUrl = `${appConfigurationService.configuration.uris.employeeAvailabilityService}`;
    }

    public getById(id: string) {
        const url = `${this.baseUrl}/${id}`;
        return this.httpClient.get<EmployeeAvailability>(url);
    }

    public updateEmployeeAvailabilityById(id: string, updatedEmployeeAvailability: EmployeeAvailability) {
        const url = `${this.baseUrl}/${id}`;
        return this.httpClient.put<EmployeeAvailability>(url, updatedEmployeeAvailability);
    }
}
