import * as i0 from '@angular/core';
import { Injectable, inject, NgModule } from '@angular/core';
import { of, filter, map, EMPTY, combineLatest, mergeMap, catchError, from, withLatestFrom } from 'rxjs';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import * as i1 from '@ngrx/store';
import { createAction, props, createFeatureSelector, createSelector, Store, createReducer, on, StoreModule } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import duration from 'dayjs/plugin/duration';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import * as i2 from '@ngrx/effects';
import { Actions, createEffect, ofType, EffectsModule } from '@ngrx/effects';
import isBetween from 'dayjs/plugin/isBetween';
class PrivaVariableDataService {}
var ServiceType;
(function (ServiceType) {
  ServiceType["Service"] = "service";
  ServiceType["Stream"] = "stream";
})(ServiceType || (ServiceType = {}));
class PrivaManualService {
  constructor() {
    this.source = 'manual';
    this.type = ServiceType.Service;
  }
  getData(requestData, _offsetInMinutes, _start, _end) {
    const data = {
      type: this.source,
      data: requestData.map(requestContainer => ({
        props: requestContainer,
        values: requestContainer.request.values
      }))
    };
    return of(data);
  }
  static {
    this.ɵfac = function PrivaManualService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaManualService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaManualService,
      factory: PrivaManualService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaManualService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function isDefined(...arg) {
  const check = thing => thing !== undefined && thing !== null;
  return arg.length ? check(arg[0]) : check;
}
const loadTelemetryDataIfNeeded = createAction('[Telemetry Service] Load data if needed', props());
const loadTelemetryData = createAction('[Telemetry Service] Load data', props());
const loadTelemetryDataSuccess = createAction('[Telemetry Service] Load data success', props());
const setTelemetryData = createAction('[Telemetry Service] Set data', props());
const selectTelemetry = createFeatureSelector('telemetry');
const selectTelemetryData = createSelector(selectTelemetry, ({
  data
}) => data);
function copyVariableReference({
  deviceId,
  deviceGroupId,
  variableId
}) {
  return {
    deviceId,
    deviceGroupId,
    variableId
  };
}
function isVariableSameAsRequest(variable) {
  return ({
    siteId,
    deviceId,
    deviceGroupId,
    variableId
  }) => variable.siteId === siteId && variable.deviceId === deviceId && variable.deviceGroupId === deviceGroupId && variable.variableId === variableId;
}
dayjs.extend(utc);
dayjs.extend(duration);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
class PrivaTelemetryBaseService {
  constructor() {
    this.http = inject(HttpClient);
    this._store = inject(Store);
    this.source = 'telemetry';
  }
  filterStateData(data, start, end) {
    return data?.some(({
      slots
    }) => slots.some(({
      date
    }) => date.isSameOrAfter(start) && date.isSameOrBefore(end))) ?? false;
  }
  mapStateData(data, start, end) {
    return {
      type: this.source,
      data: data.map(({
        requestContainer,
        slots
      }) => ({
        props: requestContainer,
        values: slots.filter(({
          date
        }) => date.isSameOrAfter(start) && date.isSameOrBefore(end)).flatMap(({
          values
        }) => values)
      }))
    };
  }
  getProps(variable, requests) {
    const requestContainer = requests.find(({
      request
    }) => isVariableSameAsRequest(variable)(request));
    if (!requestContainer) {
      throw new Error(`Request not found for variable: ${variable.variableId} and site ${variable.siteId}`);
    }
    return requestContainer;
  }
}
dayjs.extend(localizedFormat);
dayjs.extend(utc);
const aggregateDateFormat = 'YYYY-MM-DD';
class PrivaTelemetryAggregateService extends PrivaTelemetryBaseService {
  constructor() {
    super(...arguments);
    this.baseUrl = '/api/telemetry/aggregates';
    this.source = 'telemetry-aggregate';
    this.type = ServiceType.Service;
  }
  getData(requests, timestampOffset, start, end) {
    // Let the cache decide if we need to fetch the data
    const startDate = dayjs.utc(start);
    const endDate = dayjs.utc(end);
    this._store.dispatch(loadTelemetryDataIfNeeded({
      startDate,
      endDate,
      timestampOffset,
      requests,
      fetchMethod: (start, end, offset, requests) => this.fetchData(start, end, offset, requests)
    }));
    // Return the data from the cache
    return this._store.select(selectTelemetryData).pipe(filter(data => this.filterStateData(data, start, end)), map(data => this.mapStateData(data, start, end)));
  }
  fetchData(start, end, timestampOffset, requests) {
    const startTime = this.requestTimestamp(start, timestampOffset);
    const endTime = this.requestTimestamp(end, timestampOffset);
    const requestsPerSite = this.createRequestsPerSite(requests, startTime, endTime);
    const responsePerSite$ = Object.entries(requestsPerSite).map(([siteId, body]) => this.http.post(`${this.baseUrl}/${siteId}`, body));
    if (!responsePerSite$.length) return EMPTY;
    return combineLatest(responsePerSite$).pipe(map(responses => this.mapResponsesData(responses, requests)));
  }
  createRequestsPerSite(requestData, localStartTime, localEndTime) {
    return requestData.reduce((acc, {
      request
    }) => {
      const siteRequest = acc[request.siteId] ??= {
        localStartTime,
        localEndTime,
        variableReferenceList: []
      };
      siteRequest.variableReferenceList.push(copyVariableReference(request));
      return acc;
    }, {});
  }
  mapResponsesData(responses, requestData) {
    return {
      type: this.source,
      data: responses.flatMap(({
        variables
      }) => variables.map(variable => ({
        props: this.getProps(variable, requestData),
        values: variable.samples.map(sample => ({
          x: this.getTimeStamp(sample),
          y: sample.value
        }))
      })))
    };
  }
  getTimeStamp(sample) {
    // Since dayjs uses the local timezone by default, we need to convert the local date to UTC
    return dayjs.utc(sample.localDate).startOf('day');
  }
  requestTimestamp(t, offsetInMinutes) {
    if (isDefined(offsetInMinutes)) t = t.subtract(offsetInMinutes, 'minute');
    return t.format(aggregateDateFormat);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵPrivaTelemetryAggregateService_BaseFactory;
      return function PrivaTelemetryAggregateService_Factory(__ngFactoryType__) {
        return (ɵPrivaTelemetryAggregateService_BaseFactory || (ɵPrivaTelemetryAggregateService_BaseFactory = i0.ɵɵgetInheritedFactory(PrivaTelemetryAggregateService)))(__ngFactoryType__ || PrivaTelemetryAggregateService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaTelemetryAggregateService,
      factory: PrivaTelemetryAggregateService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTelemetryAggregateService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
dayjs.extend(utc);
dayjs.extend(duration);
const dayInMs = 24 * 60 * 60 * 1000;
class PrivaTelemetryRawService extends PrivaTelemetryBaseService {
  constructor() {
    super(...arguments);
    this.baseUrl = '/api/telemetry/history';
    this.source = 'telemetry-raw';
    this.type = ServiceType.Service;
  }
  getData(requests, timestampOffset, start, end) {
    // Let the cache decide if we need to fetch the data
    this._store.dispatch(loadTelemetryDataIfNeeded({
      startDate: dayjs(start),
      endDate: dayjs(end),
      timestampOffset,
      requests,
      fetchMethod: (start, end, offset, requests) => this.fetchData(start, end, offset, requests)
    }));
    // Return the data from the cache; use day before to cope with timezone differences
    const daybefore = new Date(start.valueOf() - dayInMs);
    return this._store.select(selectTelemetryData).pipe(filter(data => this.filterStateData(data, daybefore, end)), map(data => this.mapStateData(data, daybefore, end)));
  }
  fetchData(start, end, timestampOffset, requests) {
    const startTime = this.requestTimestamp(start, timestampOffset);
    const endTime = this.requestTimestamp(end, timestampOffset);
    const requestsPerSite = this.createRequestsPerSite(requests, startTime, endTime);
    const responsePerSite$ = Object.entries(requestsPerSite).map(([siteId, body]) => this.http.post(`${this.baseUrl}/${siteId}`, body));
    if (!responsePerSite$.length) return EMPTY;
    return combineLatest(responsePerSite$).pipe(map(responses => this.mapResponsesData(responses, requests, timestampOffset)));
  }
  createRequestsPerSite(requestData, startTime, endTime) {
    return requestData.reduce((acc, {
      request
    }) => {
      const siteRequest = acc[request.siteId] ??= {
        startTime,
        endTime,
        includeLastKnownValue: true,
        lastKnownValueLookbackDays: 1,
        variableReferenceList: []
      };
      siteRequest.variableReferenceList.push(copyVariableReference(request));
      return acc;
    }, {});
  }
  mapResponsesData(responses, requestData, timestampOffset) {
    return {
      type: this.source,
      data: responses.flatMap(({
        variables
      }) => variables.map(variable => ({
        props: this.getProps(variable, requestData),
        values: variable.samples.map(sample => ({
          x: this.getTimeStamp(sample, timestampOffset),
          y: sample.value
        }))
      })))
    };
  }
  getTimeStamp(sample, offsetInMinutes) {
    const rawTimeStamp = dayjs(sample.timestamp);
    if (!isDefined(offsetInMinutes)) {
      const [hours, minutes] = sample.timestampOffset.split(':').map(val => parseInt(val));
      offsetInMinutes = dayjs.duration({
        hours,
        minutes
      }).asMinutes();
    }
    return rawTimeStamp.add(offsetInMinutes, 'minute');
  }
  requestTimestamp(t, gatewayOffsetInMinutes) {
    if (isDefined(gatewayOffsetInMinutes)) {
      // because the datepicker is in local (user) time and request API expects UTC time
      // i.e start time 00:00 local (user) == 22:00 previous day
      const browserOffsetInMinutes = new Date().getTimezoneOffset();
      const difference = gatewayOffsetInMinutes + browserOffsetInMinutes;
      t = t.subtract(difference, 'minute');
    }
    return t.toISOString();
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵPrivaTelemetryRawService_BaseFactory;
      return function PrivaTelemetryRawService_Factory(__ngFactoryType__) {
        return (ɵPrivaTelemetryRawService_BaseFactory || (ɵPrivaTelemetryRawService_BaseFactory = i0.ɵɵgetInheritedFactory(PrivaTelemetryRawService)))(__ngFactoryType__ || PrivaTelemetryRawService);
      };
    })();
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaTelemetryRawService,
      factory: PrivaTelemetryRawService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTelemetryRawService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
function provideDefaultTelemetry(_options) {
  return [{
    provide: PrivaVariableDataService,
    useClass: PrivaTelemetryRawService,
    multi: true
  }, {
    provide: PrivaVariableDataService,
    useClass: PrivaTelemetryAggregateService,
    multi: true
  }];
}
var VariableDataService;
(function (VariableDataService) {
  VariableDataService["telemetryRaw"] = "telemetry-raw";
  VariableDataService["telemetryAggregate"] = "telemetry-aggregate";
})(VariableDataService || (VariableDataService = {}));
class PrivaTelemetryApiEffects {
  constructor() {
    this.actions$ = inject(Actions);
    this.loadTelemetryData$ = createEffect(() => this.actions$.pipe(ofType(loadTelemetryData), mergeMap(action => this.fetchTelemetryData(action))));
  }
  fetchTelemetryData(action) {
    const {
      startDate,
      endDate,
      timestampOffset,
      requests,
      fetchMethod
    } = action;
    if (typeof fetchMethod !== 'function') return EMPTY;
    return fetchMethod(startDate, endDate, timestampOffset, requests).pipe(map(result => loadTelemetryDataSuccess({
      startDate,
      endDate,
      requests,
      result
    })), catchError(() => EMPTY));
  }
  static {
    this.ɵfac = function PrivaTelemetryApiEffects_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaTelemetryApiEffects)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaTelemetryApiEffects,
      factory: PrivaTelemetryApiEffects.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTelemetryApiEffects, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class PrivaTelemetryCachingService {
  createLoadActions(startDate, endDate, requests, timestampOffset, fetchMethod, stateData) {
    const missingDateRanges = this.createMissingDateRanges(startDate, endDate, requests, stateData);
    if (missingDateRanges.length > 0) {
      // We don't have the data complete, so request it
      return from(missingDateRanges.map(([startDate, endDate]) => loadTelemetryData({
        startDate,
        endDate,
        timestampOffset,
        requests,
        fetchMethod
      })));
    } else {
      // We have the data already present, so indicate it is ready.
      return EMPTY;
    }
  }
  /*
   * This method merges the received response data with the data from the store.
   */
  mergeSeriesData(startDate, endDate, requests, response, dataFromStore) {
    const dates = this.getDates(startDate, endDate);
    const data = [...dataFromStore];
    // Iterate over requests to update data
    requests.forEach(request => {
      const dataIndex = this.getOrCreateDataIndex(data, request);
      const slots = data[dataIndex].slots.slice(); // Create a shallow copy of slots array
      // Update values for each date
      dates.forEach(date => {
        const slotIndex = this.getOrCreateSlotIndex(slots, date);
        const values = this.getDayValues(response, request, date);
        slots[slotIndex] = {
          ...slots[slotIndex],
          values
        };
      });
      // Sort slots by date
      slots.sort((first, second) => first.date.unix() - second.date.unix());
      // Update data with modified slots
      data[dataIndex] = {
        ...data[dataIndex],
        slots
      };
    });
    return data;
  }
  /*
   * This method creates date ranges between start and end date that doesn't have data in the store.
   */
  createMissingDateRanges(startDate, endDate, requests, stateData) {
    // Get an array of all the dates that do not have data in the store
    const missingDates = this.getMissingDates(startDate, endDate, requests, stateData);
    if (missingDates.length === 0) {
      // Apparently we have all the data already
      return [];
    }
    return this.createDateRanges(missingDates);
  }
  /*
   * This method returns an array of dates for which no data is present in the store.
   * It doesn't look at the values itself, but at the slots. So when a slot is present,
   * but has no values, it will not show up in the missing dates array.
   */
  getMissingDates(startDate, endDate, requests, dataFromStore) {
    const missingDates = new Set(); // Store Unix timestamps for uniqueness and efficiency
    // Always add today to the missing dates if today is in the requested range
    const today = dayjs().startOf('day');
    if (today.isBetween(startDate, endDate, null, '[]')) {
      missingDates.add(today.unix());
    }
    // Convert dataFromStore into a Map for quick access
    const dataMap = new Map(dataFromStore.map(item => [item.requestContainer.variableId, item]));
    // Get the range of dates between the start and end date including start and end date
    const dates = this.getDates(startDate, endDate);
    dates.forEach(date => {
      requests.forEach(request => {
        const data = dataMap.get(request.variableId);
        if (!data) {
          // If no data is found for this request, mark the date as missing
          missingDates.add(date.unix());
        } else {
          // Use a Set to store the Unix timestamps of the slots
          const slotDates = new Set(data.slots.map(slot => slot.date.unix()));
          if (!slotDates.has(date.unix())) {
            missingDates.add(date.unix());
          }
        }
      });
    });
    // Convert the Set back to an array of Day.js objects
    return Array.from(missingDates, ts => dayjs.unix(ts));
  }
  /*
   * The purpose of this method is to create date ranges as full days, so from 00:00:00 to 23:59:59.
   * The range can be one day or multiple days.
   */
  createDateRanges(dates) {
    const dateRanges = [];
    if (!dates.length) return dateRanges;
    // We start at the first date...
    let firstDate = dates[0];
    for (let idx = 1; idx < dates.length; idx++) {
      // ... and continue searching for a date that differs more than 1 day.
      if (dates[idx].diff(dates[idx - 1], 'day') !== 1) {
        // Yes, we found a difference more than one, so the range is from the first date to this date.
        const endDate = dates[idx - 1].endOf('day');
        dateRanges.push([firstDate, endDate]);
        firstDate = dates[idx];
      }
    }
    // Always add the last date range
    const lastDate = dates[dates.length - 1].endOf('day');
    dateRanges.push([firstDate, lastDate]);
    return dateRanges;
  }
  /*
   * This method returns an array of dates between the start and end date, including the start and end date.
   */
  getDates(startDate, endDate) {
    const dates = [];
    let currentDate = startDate.startOf('day');
    while (currentDate.isSameOrBefore(endDate)) {
      dates.push(currentDate);
      currentDate = currentDate.add(1, 'day');
    }
    return dates;
  }
  /*
   * This method tries to find the data for the specified request and return its index.
   * When not found, it will create a new data entry and return its index.
   */
  getOrCreateDataIndex(stateData, requestContainer) {
    const index = stateData.findIndex(({
      requestContainer: {
        variableId
      }
    }) => variableId === requestContainer.variableId);
    if (index !== -1) return index; // existing data found
    stateData.push({
      requestContainer,
      slots: []
    });
    return stateData.length - 1;
  }
  /*
   * This method tries to find the slot for the specified date and return its index.
   * When not found, it will create a new slot and return its index.
   */
  getOrCreateSlotIndex(slots, slotDate) {
    const index = slots.findIndex(({
      date
    }) => date.isSame(slotDate));
    if (index !== -1) return index; // existing data found
    slots.push({
      date: slotDate,
      values: []
    });
    return slots.length - 1;
  }
  /*
   * This method will filter the values for the request from the response and the specified date.
   */
  getDayValues(response, request, date) {
    const responseValues = response.data.find(({
      props: {
        variableId
      }
    }) => variableId === request.variableId);
    if (!responseValues) return [];
    // Define the start and end of the day corrected with user browser timezone to match the stored response values
    const offset = new Date().getTimezoneOffset();
    const startOfDay = date.startOf('day').subtract(offset, 'minutes');
    const endOfDay = date.endOf('day').subtract(offset, 'minutes');
    // Filter values that fall within the day range
    return responseValues.values.filter(value => {
      const valueDate = dayjs(value.x);
      return valueDate.isBetween(startOfDay, endOfDay, null, '[]'); // Inclusive of both ends
    });
  }
  static {
    this.ɵfac = function PrivaTelemetryCachingService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaTelemetryCachingService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaTelemetryCachingService,
      factory: PrivaTelemetryCachingService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTelemetryCachingService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
dayjs.extend(isBetween);
class PrivaTelemetryEffects {
  constructor() {
    this.store = inject(Store);
    this.actions$ = inject(Actions);
    this.cachingService = inject(PrivaTelemetryCachingService);
    /* NOTE: All dates are treated as local unless otherwise stated */
    /**
     * This effect checks if the data is already in the store.
     * If not: then it will fire an action to retrieve the data
     */
    this.loadSeriesDataIfNeeded$ = createEffect(() => {
      return this.actions$.pipe(ofType(loadTelemetryDataIfNeeded), withLatestFrom(this.store.select(selectTelemetryData)), mergeMap(([{
        startDate,
        endDate,
        requests,
        timestampOffset,
        fetchMethod
      }, stateData]) => {
        return this.cachingService.createLoadActions(startDate, endDate, requests, timestampOffset, fetchMethod, stateData);
      }));
    });
    /**
     * This effect will merge the received data with the data from the store and fire an action to store it.
     */
    this.mergeSeriesData$ = createEffect(() => {
      return this.actions$.pipe(ofType(loadTelemetryDataSuccess), withLatestFrom(this.store.select(selectTelemetryData)), map(([{
        startDate,
        endDate,
        requests,
        result
      }, stateData]) => {
        const updatedData = this.cachingService.mergeSeriesData(startDate, endDate, requests, result, stateData);
        return setTelemetryData({
          data: updatedData
        });
      }));
    });
  }
  static {
    this.ɵfac = function PrivaTelemetryEffects_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaTelemetryEffects)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PrivaTelemetryEffects,
      factory: PrivaTelemetryEffects.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTelemetryEffects, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const initialTelemetryState = {
  data: []
};
const telemetryReducer = createReducer(initialTelemetryState, on(setTelemetryData, (state, {
  data
}) => ({
  ...state,
  data
})));
function reducer(state, action) {
  return telemetryReducer(state, action);
}
class PrivaTelemetryModule {
  static {
    this.ɵfac = function PrivaTelemetryModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || PrivaTelemetryModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: PrivaTelemetryModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [StoreModule.forFeature('telemetry', reducer), EffectsModule.forFeature([PrivaTelemetryEffects, PrivaTelemetryApiEffects])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PrivaTelemetryModule, [{
    type: NgModule,
    args: [{
      imports: [StoreModule.forFeature('telemetry', reducer), EffectsModule.forFeature([PrivaTelemetryEffects, PrivaTelemetryApiEffects])]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { PrivaManualService, PrivaTelemetryAggregateService, PrivaTelemetryModule, PrivaVariableDataService, ServiceType, VariableDataService, provideDefaultTelemetry };
