import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = '';
                if (error.error instanceof ErrorEvent) {
                    // Client-side error
                    errorMessage = `Client-side error - ${error.error.message}`;
                } else {
                    // Server-side error
                    errorMessage = `Server-side error - ${error.message}`;
                }
                return throwError(() => ({
                    status: error.status,
                    message: errorMessage,
                }));
            }),
        );
    }
}
