import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of } from 'rxjs';

import { SitesService } from '../services/sites.service';
import { SitesActions } from './sites.actions';

@Injectable()
export class SitesEffects {
    constructor(
        private actions$: Actions,
        private siteService: SitesService,
    ) {}

    loadSites$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SitesActions.loadSites),
            concatMap(() =>
                this.siteService.getAll().pipe(
                    map((result) => {
                        const sites = result.map((site) => ({
                            id: site.id,
                            value: site.name,
                            label: site.name,
                        }));
                        return SitesActions.loadSitesSuccess({ sites });
                    }),
                    catchError((error) => of(SitesActions.loadSitesFailure({ message: error }))),
                ),
            ),
        ),
    );
}
