import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

import { AppConfiguration, windowWithPrivaConfig } from './app-configuration.model';

@Injectable()
export class AppConfigurationService {
    protected config = windowWithPrivaConfig.privaConfig;
    private configurationLoaded$ = new ReplaySubject<boolean>(1);

    public get configuration(): AppConfiguration {
        return this.config;
    }

    public loadConfiguration() {
        this.configurationLoaded$.next(!!this.config);
    }

    public get configurationLoaded(): Observable<boolean> {
        return this.configurationLoaded$.asObservable();
    }
}
