import { createAction, props } from '@ngrx/store';

import { DynamicComponent } from '@priva/components/dynamic';

export const openDialog = createAction('[App] Open dialog', props<{ dialog: DynamicComponent }>());
export const closeDialog = createAction('[App] Close dialog');

export const openPanel = createAction('[App] Open panel', props<{ panel: DynamicComponent }>());
export const closePanel = createAction('[App] Close panel');

export const resetState = createAction('[App] Reset state');
