import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigatedAction } from '@ngrx/router-store';
import { filter, map } from 'rxjs';

import { resetState } from './app.actions';

@Injectable({
    providedIn: 'root',
})
export class AppEffects {
    constructor(private actions$: Actions) {}

    public routerNavigated$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ROUTER_NAVIGATED),
            filter(
                (action: RouterNavigatedAction) =>
                    action.payload.event.url === '/' || action.payload.event.url.startsWith('/?'),
            ),
            map(() => resetState()),
        );
    });
}
