import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppConfigurationService } from 'app/common/configuration';

import { RoleFormatBE } from '../models/role.model';

@Injectable({
    providedIn: 'root',
})
export class RoleService {
    private baseUrl: string = '';

    constructor(
        private httpClient: HttpClient,
        appConfigurationService: AppConfigurationService,
    ) {
        this.baseUrl = `${appConfigurationService.configuration.uris?.roleService}`;
    }

    public getAll() {
        const url = `${this.baseUrl}`;
        return this.httpClient.get<RoleFormatBE[]>(url);
    }
}
