import { createReducer, on } from '@ngrx/store';

import { Role } from '../models/role.model';
import { RolesActions } from './roles.actions';

export interface RolesState {
    loading: boolean;
    roles: Role[];
    errorMessage: string;
}

const initalState: RolesState = {
    loading: true,
    roles: [],
    errorMessage: '',
};

export const rolesReducer = createReducer(
    initalState,
    on(RolesActions.loadRoles, (state) => ({
        ...state,
        loading: true,
        roles: [],
        errorMessage: '',
    })),
    on(RolesActions.loadRolesSuccess, (state, { roles }) => ({
        ...state,
        loading: false,
        roles: roles,
    })),
    on(RolesActions.loadRolesFailure, (state, { message }) => ({
        ...state,
        roles: [],
        errorMessage: message,
        loading: false,
    })),
);
