{
    "private": true,
    "name": "labor-management",
    "version": "1.0.208",
    "engines": {
        "node": "^22",
        "npm": "^10"
    },
    "scripts": {
        "ng": "ng",
        "prepare": "cd .. && husky",
        "prestart": "npm run localize",
        "start": "run-p -r start:server start:app",
        "start:app": "ng serve --configuration development --open --watch --proxy-config proxy.conf.json",
        "start:server": "ts-node mock-server/server",
        "build": "npm run localize && ng build",
        "build:dev": "npm run localize && ng build --configuration development",
        "test": "jest --coverage --maxWorkers=50% --config=jest.config.js",
        "test:watch": "jest --coverage --maxWorkers=25% --watch",
        "lint": "npm run lint:check",
        "lint:check": "run-p lint:check:*",
        "lint:check:scripts": "cross-env NODE_OPTIONS=--max-old-space-size=8192 eslint . --cache",
        "lint:check:styles": "stylelint **/*.{css,scss} --cache",
        "lint:check:format": "prettier . --check --ignore-unknown --log-level warn",
        "lint:fix": "run-p lint:fix:*",
        "lint:fix:scripts": "cross-env NODE_OPTIONS=--max-old-space-size=8192 eslint . --fix --cache",
        "lint:fix:styles": "stylelint --fix **/*.{css,scss} --cache",
        "lint:fix:format": "prettier . --write --ignore-unknown --log-level warn",
        "analyze-bundle-size": "npm run build:source-maps && source-map-explorer dist/*.js",
        "localize": "localization-cli --sourcePaths localization/source --destinationPath src/assets/translations",
        "localize:debug": "localization-cli --verbose --sourcePaths localization/source --destinationPath src/assets/translations"
    },
    "prettier": "@priva/linter/prettier-config",
    "overrides": {
        "postcss": "$postcss",
        "@stdlib/number-float64-base-normalize@0.0.7": "0.0.8"
    },
    "dependencies": {
        "@angular/animations": "~18.0.6",
        "@angular/cdk": "~18.0.6",
        "@angular/common": "~18.0.6",
        "@angular/compiler": "~18.0.6",
        "@angular/core": "~18.0.6",
        "@angular/forms": "~18.0.6",
        "@angular/platform-browser": "~18.0.6",
        "@angular/platform-browser-dynamic": "~18.0.6",
        "@angular/router": "~18.0.6",
        "@angular/service-worker": "~18.0.6",
        "@ng-select/ng-select": "^13.7.1",
        "@ngrx/effects": "~18.0.1",
        "@ngrx/router-store": "~18.0.1",
        "@ngrx/store": "~18.0.1",
        "@ngrx/store-devtools": "~18.0.1",
        "@ngx-translate/core": "^15.0.0",
        "@popperjs/core": "^2.11.8",
        "@priva/analytics": "^13.0.0",
        "@priva/appshell": "^27.0.0",
        "@priva/auth": "^14.0.0",
        "@priva/components": "^49.3.1",
        "@priva/error-pages": "^16.0.0",
        "@priva/localization": "^15.0.0",
        "@priva/styles": "^32.7.2",
        "@priva/utilities": "^13.2.0",
        "@segment/analytics-next": "^1.70.0",
        "date-fns": "^2.30.0",
        "dayjs": "^1.11.9",
        "focus-visible": "^5.2.0",
        "ng2-date-picker": "~18.0.0",
        "ngx-window-token": "^7.0.0",
        "rxjs": "^7.8.1",
        "tslib": "^2.6.3",
        "zone.js": "~0.14.5"
    },
    "devDependencies": {
        "@angular-devkit/build-angular": "~18.0.7",
        "@angular-devkit/core": "~18.0.7",
        "@angular-devkit/schematics": "~18.0.7",
        "@angular/cli": "~18.0.7",
        "@angular/compiler-cli": "~18.0.6",
        "@angular/language-service": "~18.0.6",
        "@priva/linter": "^9.2.0",
        "@priva/localization-cli": "^5.2.0",
        "@priva/mock-server": "^1.3.0",
        "@types/jest": "^29.5.12",
        "@types/node": "^22.9.0",
        "@types/uuid": "^10.0.0",
        "cross-env": "^7.0.3",
        "eslint": "^8.57.0",
        "husky": "^9.0.11",
        "jest": "^29.7.0",
        "jest-preset-angular": "^14.1.1",
        "lint-staged": "^15.2.7",
        "ng-mocks": "^14.13.0",
        "npm-run-all": "^4.1.5",
        "postcss": "^8.4.39",
        "postcss-scss": "^4.0.9",
        "prettier": "^3.3.2",
        "source-map-explorer": "^2.5.3",
        "stylelint": "^16.6.1",
        "ts-jest": "^29.1.5",
        "ts-node": "^10.9.2",
        "typescript": "~5.4.5"
    }
}
