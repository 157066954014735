<div
    id="root"
    [class.has-notification-bar]="hasNotificationBar"
    [class.has-primary-nav]="hasPrimaryNav"
    [appshell-deactivate-area]="[AppShellAreaType.SidebarLocalLeft]"
    [priva-toggle-class]="{ contentActions: 'has-content-overlay' }"
    [priva-toggle-deactivate]="['contentActions', 'showPrimaryNavItems']"
>
    <!-- AREA | COMPONENT: Notification messages -->
    <priva-notifications />

    <!-- App Shell main component -->
    <router-outlet />
</div>
