import { DynamicComponent } from '@priva/components/dynamic';

export interface AppState {
    activeDialogs: DynamicComponent[];
    activePanel?: DynamicComponent;
}

export const initialAppState: AppState = {
    activeDialogs: [],
};
