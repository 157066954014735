import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { PrimaryNavigationService, PrivaNavigationItem } from '@priva/appshell';

@Injectable()
export class AppPrimaryNavigationService implements PrimaryNavigationService {
    private navigation$: Observable<PrivaNavigationItem[]> = of([
        {
            label: 'Employees',
            label_short: 'Employees',
            slug: 'employees',
            url: '/',
            icon: 'fa-regular fa-users',
        },
        {
            label: 'Activities',
            label_short: 'Activities',
            slug: 'activities',
            url: '/activities',
            icon: 'fa-regular fa-hammer',
        },
    ]);

    public get navigation(): Observable<PrivaNavigationItem[]> {
        return this.navigation$;
    }
}
