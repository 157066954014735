/**
 * Compose predicate method to filter out duplicated elements; although intended for string or number array,
 * it can be used for any array; will compare with `===` operator, so beware when using with objects.
 * Usage: e.g. `array.filter(distinct())`;
 *
 * @returns array filter method
 */
function distinct() {
  return (val, index, arr) => index === arr.findIndex(val2 => val2 === val);
}
/**
 * Compose predicate method to filter out distint elements with a field method to produce a field or a key,
 * based on the contents, for example:
 * - `array.filter(distinctBy((elm) => elm.id))`;
 * - `array.filter(distinctBy(({ name, version }) => (name + version)))`;
 * - etc.
 *
 * @param field method to select a (combination of) field(s)
 * @returns array filter method
 */
function distinctBy(field) {
  return (val, index, arr) => index === arr.findIndex(val2 => field(val2) === field(val));
}

/**
 * Predicate to test if element is truthy, i.e. not 0, `false`, `null`, `undefined`, or empty string
 */
function isTruthy() {
  return thing => !!thing;
}
function isDefined(...arg) {
  const check = thing => thing !== undefined && thing !== null;
  return arg.length ? check(arg[0]) : check;
}

/**
 * Predicate to help find/filter; search/match objects with the specified `id`.
 * @param anId an id to search for
 * @returns a predicate method to match objects having the specified `id`
 */
function withId(anId) {
  return ({
    id
  }) => id === anId;
}
function withType(aType) {
  return ({
    type
  }) => type === aType;
}

/**
 * Generated bundle index. Do not edit.
 */

export { distinct, distinctBy, isDefined, isTruthy, withId, withType };
