import { Component, OnDestroy, OnInit } from '@angular/core';
import { first, Subject, takeUntil } from 'rxjs';

import { AppShellAreaService, AppShellAreaType } from '@priva/appshell';
import { PrivaNotificationsService } from '@priva/components/notifications';
import { PrivaLocalizationService } from '@priva/localization';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
    public AppShellAreaType = AppShellAreaType;

    public hasNotificationBar = false;
    public hasPrimaryNav = true;

    private unsubscribe$: Subject<void> = new Subject();

    constructor(
        private notificationService: PrivaNotificationsService,
        private appShellAreaService: AppShellAreaService,
        localization: PrivaLocalizationService,
    ) {
        localization.language.pipe(first()).subscribe();
    }

    public ngOnInit() {
        /* TODO: refactor these manual subscribes (use async pipe) */

        // Has notification bar
        this.notificationService.barVisibility
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((visible) => (this.hasNotificationBar = visible));

        // Has primary nav
        this.appShellAreaService
            .hasArea(AppShellAreaType.PrimaryNav)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((available: boolean) => (this.hasPrimaryNav = available));
    }

    public ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}
