import { Action, createReducer, on } from '@ngrx/store';

import { DynamicComponent } from '@priva/components/dynamic';

import { closeDialog, closePanel, openDialog, openPanel, resetState } from './app.actions';
import { AppState, initialAppState } from './app.state';

/* istanbul ignore next, standard reducer pattern */
const reducer = createReducer<AppState>(
    initialAppState,

    on(openDialog, (state, action): AppState => {
        const activeDialogs = [
            ...state.activeDialogs.filter((d) => d.component !== action.dialog.component),
            action.dialog,
        ];
        return { ...state, activeDialogs };
    }),

    on(closeDialog, (state, _action): AppState => {
        const activeDialogs: DynamicComponent[] = [...state.activeDialogs];
        activeDialogs.pop();
        return { ...state, activeDialogs };
    }),

    on(openPanel, (state, action): AppState => {
        return {
            ...state,
            activePanel: action.panel,
        };
    }),

    on(closePanel, (state, _action): AppState => {
        return {
            ...state,
            activePanel: undefined,
        };
    }),

    on(resetState, (_state, _action): AppState => {
        return {
            ...initialAppState,
        };
    }),
);

export function appReducer(state: AppState | undefined, action: Action) {
    return reducer(state, action);
}
